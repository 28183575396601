import React, { lazy } from "react";

const BtoILandingPage = lazy(() =>
  import("./pages/BtoIPages/BtoILandingPage/BtoILandingPage")
);
const BtoICandidatesListPage = lazy(() =>
  import("./pages/BtoIPages/BtoICandidatesListPage/BtoICandidatesListPage")
);
const BtoICandidatesReportsListPage = lazy(() =>
  import(
    "./pages/BtoIPages/BtoICandidatesReportsListPage/BtoICandidatesReportsListPage"
  )
);
const BtoISingleCandidateReportsPage = lazy(() =>
  import(
    "./pages/BtoIPages/BtoISingleCandidateReportsPage/BtoISingleCandidateReportsPage"
  )
);
const AllBusinessPage = lazy(() =>
  import("./pages/AdminDashboardPages/AllBusinessPage/AllBusinessPage")
);
const BusinessNewTestOnBoarding = lazy(() =>
  import(
    "./pages/AdminDashboardPages/AllBusinessPage/BusinessOnBoarding/BusinessNewTestOnBoarding"
  )
);
const BusinessOnBoarding = lazy(() =>
  import(
    "./pages/AdminDashboardPages/AllBusinessPage/BusinessOnBoarding/BusinessOnBoarding"
  )
);
const CodingRoundCompanySelectPage = lazy(() =>
  import(
    "./pages/CodingRoundCompanySelectionPage/CodingRoundCompanySelectionPage"
  )
);
const CampusAmbassadorApplications = lazy(() =>
  import(
    "./pages/AdminDashboardPages/CampusAmbassadorApplications/CampusAmbassadorApplications"
  )
);
const CampusAmbassadorPage = lazy(() =>
  import("./pages/CampusAmbassadorPage/CampusAmbassadorPage")
);
const DailyInterviewProblemPage = lazy(() =>
  import("./pages/DailyInterviewProblem/DailyInterviewProblemPage")
);
const InternWorkTrackPage = lazy(() =>
  import("../Global/pages/InternWorkTrackPage/InternWorkTrackPage")
);
const PracticePage = lazy(() => import("./pages/PracticePage/PracticePage"));
const HiringPage = lazy(() => import("./pages/HiringPage/HiringPage"));
const HiringCompanySelectionPage = lazy(() =>
  import("./pages/HiringCompanySelectionPage/HiringCompanySelectionPage")
);
const HiringProcessPage = lazy(() =>
  import("./pages/HiringProcessPage/HiringProcessPage")
);
const InternTaskUpdates = lazy(() =>
  import("./pages/AdminDashboardPages/InternTaskUpdates/InternTaskUpdates")
);
const OnlineTestQuestionsListPage = lazy(() =>
  import(
    "./pages/AdminDashboardPages/OnlineTestQuestionsListPage/OnlineTestQuestionsListPage"
  )
);
const ReportedObjectiveQuestionsList = lazy(() =>
  import(
    "./pages/AdminDashboardPages/ReportedObjectiveQuestionsList/ReportedObjectiveQuestionsList"
  )
);
const ReportedCodingQuestionsList = lazy(() =>
  import(
    "./pages/AdminDashboardPages/ReportedCodingQuestionsList/ReportedCodingQuestionsList"
  )
);
const InterviewFreeDemoPage = lazy(() =>
  import("./pages/InterviewFreeDemoPage/InterviewFreeDemoPage")
);
const PrivacyPolicy = lazy(() =>
  import("../Global/pages/PrivacyPolicy/PrivacyPolicy")
);
const ResumeEvaluaitionsDashboard = lazy(() =>
  import(
    "./pages/AdminDashboardPages/ResumeEvaluaitionsDashboard/ResumeEvaluaitionsDashboard"
  )
);
const InterviewConversationsDashboard = lazy(() =>
  import(
    "./pages/AdminDashboardPages/InterviewConversationsDashboard/InterviewConversationsDashboard"
  )
);
const InterviewProcessedConversationsDashboard = lazy(() =>
  import(
    "./pages/AdminDashboardPages/InterviewConversationsDashboard/InterviewProcessedConversationsDashboard"
  )
);
const ResumeEvaluaitorReports = lazy(() =>
  import(
    "./pages/ResumeEvaluaitor/ResumeEvaluaitorReports/ResumeEvaluaitorReports"
  )
);
const Blog1 = lazy(() => import("../Global/pages/Blogs/Blog1"));
const Blog2 = lazy(() => import("../Global/pages/Blogs/Blog2"));
const Blog3 = lazy(() => import("../Global/pages/Blogs/Blog3"));
const Blog4 = lazy(() => import("../Global/pages/Blogs/Blog4"));
const Blog5 = lazy(() => import("../Global/pages/Blogs/Blog5"));
const Blog6 = lazy(() => import("../Global/pages/Blogs/Blog6"));
const Blog7 = lazy(() => import("../Global/pages/Blogs/Blog7"));
const Blog8 = lazy(() => import("../Global/pages/Blogs/Blog8"));
const Blog9 = lazy(() => import("../Global/pages/Blogs/Blog9"));
const Blog10 = lazy(() => import("../Global/pages/Blogs/Blog10"));
const BlogsHome = lazy(() => import("../Global/pages/Blogs/BlogsHome"));
const Error404Page = lazy(() =>
  import("../Global/pages/Error404Page/Error404Page")
);
const FaqPage = lazy(() => import("../Global/pages/FaqPage/FaqPage"));
const HomePage = lazy(() => import("./pages/HomePage/HomePage"));
const LinkedinSuccess = lazy(() =>
  import("./pages/LinkedinSuccess/LinkedinSuccess")
);
const AddNotificationAdminDashboard = lazy(() =>
  import(
    "./pages/AdminDashboardPages/AddNotificationAdminDashboard/AddNotificationAdminDashboard"
  )
);
const NotificationsPage = lazy(() =>
  import("./pages/NotificationsPage/NotificationsPage")
);
const OurTeam = lazy(() => import("./pages/OurTeam/OurTeam"));
const PaymentSuccess = lazy(() =>
  import("./pages/PaymentSuccess/PaymentSuccess")
);
const AddPotdProblemsToDBDashboard = lazy(() =>
  import(
    "./pages/ProblemOfTheDayPage/AddPotdProblemsToDBDashboard/AddPotdProblemsToDBDashboard"
  )
);
const PotdSolutionPage = lazy(() =>
  import("./pages/ProblemOfTheDayPage/PotdSolutionPage/PotdSolutionPage")
);
const ProfilePage = lazy(() => import("./pages/ProfilePage/ProfilePage"));
const PendingReportsPage = lazy(() =>
  import("./pages/PendingReportsPage/PendingReportsPage")
);
const ReportsPage = lazy(() => import("./pages/ReportsPage/ReportsPage"));
const ResumeEvaluaitor = lazy(() =>
  import("./pages/ResumeEvaluaitor/ResumeEvaluaitor")
);
const SelectPage = lazy(() => import("./pages/SelectPage/SelectPage"));
const OnlineTestCompanySelectPage = lazy(() =>
  import("./pages/OnlineTestCompanySelectPage/OnlineTestCompanySelectPage")
);
const AddOnlineTestProblemsToDBDashboard = lazy(() =>
  import(
    "./pages/AdminDashboardPages/AddOnlineTestProblemsToDBDashboard/AddOnlineTestProblemsToDBDashboard"
  )
);
const ShareReportPage = lazy(() =>
  import("./pages/ShareReportPage/ShareReportPage")
);
const TermsConditions = lazy(() =>
  import("../Global/pages/TermsConditions/TermsConditions")
);
const Login = lazy(() => import("./pages/auth/Login"));

const consumerRoutes = [
  {
    path: "/",
    component: HomePage,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/refer/:slug",
    component: HomePage,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/team",
    component: OurTeam,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/campus-ambassador-program",
    component: CampusAmbassadorPage,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/blogs/home",
    component: BlogsHome,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/blog/revolutionize",
    component: Blog1,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/blog/recruitment",
    component: Blog2,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/blog/diversity",
    component: Blog3,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/blog/maximizing",
    component: Blog4,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/blog/science",
    component: Blog5,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/blog/interview-rejections",
    component: Blog6,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/blog/advantages-of-ai-interviews",
    component: Blog7,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/blog/metrics-ai-uses",
    component: Blog8,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/blog/top-myths-of-ai",
    component: Blog9,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/blog/future-of-ai",
    component: Blog10,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/dip-solution/:potdSlug",
    component: PotdSolutionPage,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/report/:profileIdSlug/:interviewIDSlug",
    component: ShareReportPage,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/tnc",
    component: TermsConditions,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/privacy",
    component: PrivacyPolicy,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/faq",
    component: FaqPage,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "*",
    component: Error404Page,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/practice",
    component: (props) => <HiringPage {...props} isThisPracticePage={true} />,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/user-dashboard/:slug",
    component: ProfilePage,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: true,
  },
  {
    path: "/login",
    component: Login,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/interview-free-demo",
    component: InterviewFreeDemoPage,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: true,
  },
  // user routes
  {
    path: "/daily-interview-problem",
    component: DailyInterviewProblemPage,
    isUserRoute: true,
    isAdminRoute: false,
    hideChatBot: true,
  },
  {
    path: "/notifications",
    component: NotificationsPage,
    isUserRoute: true,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/interview",
    component: SelectPage,
    isUserRoute: true,
    isAdminRoute: false,
    hideChatBot: true,
  },
  {
    path: "/practice/:companyTypeSlug",
    component: PracticePage,
    isUserRoute: true,
    isAdminRoute: false,
    hideChatBot: true,
  },
  {
    path: "/hiring",
    component: HiringPage,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/hiring/:companyTypeSlug",
    component: HiringCompanySelectionPage,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/hiring/:companyTypeSlug/:hiringCompanySlug",
    component: HiringProcessPage,
    isUserRoute: true,
    isAdminRoute: false,
    hideChatBot: true,
  },
  {
    path: "/hiring/:companyTypeSlug/:hiringCompanySlug/:hiringProcessSlug",
    component: HiringProcessPage,
    isUserRoute: true,
    isAdminRoute: false,
    hideChatBot: true,
  },
  {
    path: "/coding-round",
    component: CodingRoundCompanySelectPage,
    isUserRoute: true,
    isAdminRoute: false,
    hideChatBot: true,
  },
  {
    path: "/interview/:interviewIDSlug",
    component: SelectPage,
    isUserRoute: true,
    isAdminRoute: false,
    hideChatBot: true,
  },
  {
    path: "/onlinetest",
    component: OnlineTestCompanySelectPage,
    isUserRoute: true,
    isAdminRoute: false,
    hideChatBot: true,
  },
  {
    path: "/pending-reports",
    component: PendingReportsPage,
    isUserRoute: true,
    isAdminRoute: false,
    hideChatBot: true,
  },
  {
    path: "/reports",
    component: ReportsPage,
    isUserRoute: true,
    isAdminRoute: false,
    hideChatBot: true,
  },
  {
    path: "/resume-evaluaitor",
    component: ResumeEvaluaitor,
    isUserRoute: true,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/payment/success/:paymentIDSlug",
    component: PaymentSuccess,
    isUserRoute: true,
    isAdminRoute: false,
    hideChatBot: true,
  },
  {
    path: "/linkedin/success",
    component: LinkedinSuccess,
    isUserRoute: true,
    isAdminRoute: false,
    hideChatBot: true,
  },
  {
    path: "/resume-evaluaition-reports",
    component: ResumeEvaluaitorReports,
    isUserRoute: true,
    isAdminRoute: false,
    hideChatBot: true,
  },
  {
    path: "/internal/tasks-update",
    component: InternWorkTrackPage,
    isUserRoute: true,
    isAdminRoute: false,
    hideChatBot: true,
  },
  {
    path: "btoi-dashboard/:btoislug",
    component: BtoILandingPage,
    isUserRoute: true,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "btoi-dashboard/:btoislug/all-candidates-details",
    component: BtoICandidatesListPage,
    isUserRoute: true,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "btoi-dashboard/:btoislug/reports/:reportTypeSlug",
    component: BtoICandidatesReportsListPage,
    isUserRoute: true,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "btoi-dashboard/:btoislug/reports/:reportTypeSlug/:userUidSlug",
    component: BtoISingleCandidateReportsPage,
    isUserRoute: true,
    isAdminRoute: false,
    hideChatBot: false,
  },
  // admin routes
  {
    path: "/admin/notifications-admin-dashboard",
    component: AddNotificationAdminDashboard,
    isUserRoute: false,
    isAdminRoute: true,
    hideChatBot: true,
  },
  {
    path: "/admin/online-test-admin-dashboard",
    component: AddOnlineTestProblemsToDBDashboard,
    isUserRoute: false,
    isAdminRoute: true,
    hideChatBot: true,
  },
  {
    path: "/admin/resume-evaluaitions",
    component: ResumeEvaluaitionsDashboard,
    isUserRoute: false,
    isAdminRoute: true,
    hideChatBot: true,
  },
  {
    path: "/admin/interview-conversations",
    component: InterviewConversationsDashboard,
    isUserRoute: false,
    isAdminRoute: true,
    hideChatBot: true,
  },
  {
    path: "admin/interview-processed-conversations",
    component: InterviewProcessedConversationsDashboard,
    isUserRoute: false,
    isAdminRoute: true,
    hideChatBot: true,
  },
  {
    path: "admin/campus-ambassador-applications",
    component: CampusAmbassadorApplications,
    isUserRoute: false,
    isAdminRoute: true,
    hideChatBot: true,
  },
  {
    path: "admin/dip-admin-dashboard",
    component: AddPotdProblemsToDBDashboard,
    isUserRoute: false,
    isAdminRoute: true,
    hideChatBot: true,
  },
  {
    path: "/admin/online-questions-list",
    component: OnlineTestQuestionsListPage,
    isUserRoute: false,
    isAdminRoute: true,
    hideChatBot: true,
  },
  {
    path: "/admin/reported-coding-questions-list",
    component: ReportedCodingQuestionsList,
    isUserRoute: false,
    isAdminRoute: true,
    hideChatBot: true,
  },
  {
    path: "/admin/reported-online-questions-list",
    component: ReportedObjectiveQuestionsList,
    isUserRoute: false,
    isAdminRoute: true,
    hideChatBot: true,
  },
  {
    path: "/admin/business-test-onboarding",
    component: BusinessNewTestOnBoarding,
    isUserRoute: false,
    isAdminRoute: true,
    hideChatBot: true,
  },
  {
    path: "/admin/business-onboarding",
    component: BusinessOnBoarding,
    isUserRoute: false,
    isAdminRoute: true,
    hideChatBot: true,
  },
  {
    path: "/admin/all-business-dashboard",
    component: AllBusinessPage,
    isUserRoute: false,
    isAdminRoute: true,
    hideChatBot: true,
  },
  {
    path: "/admin/intern-tasks-update",
    component: InternTaskUpdates,
    isUserRoute: false,
    isAdminRoute: true,
    hideChatBot: true,
  },
];
export default consumerRoutes;
