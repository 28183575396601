import { lazy } from "react";
import Error404Page from "../Global/pages/Error404Page/Error404Page";
const HomePage = lazy(() => import("./pages/BPHomePage/BPHomePage"));

const businessPublicRoutes = [
  {
    path: "/",
    component: HomePage,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
  {
    path: "/*",
    component: Error404Page,
    isUserRoute: false,
    isAdminRoute: false,
    hideChatBot: false,
  },
];
export default businessPublicRoutes;
