import BusinessDetailPage from "../BusinessAdmin/Pages/LandingPage/LandingPage";
import BusinessTestDetailPage from "../BusinessAdmin/Pages/TestResultsPage/TestResultsPage";

const adminRoutes = [
  {
    path: "/admin/onboarded-businesses/:businessSlug",
    component: BusinessDetailPage,
    isUserRoute: true,
    isAdminRoute: true,
    hideChatBot: true,
  },
  {
    path: "/admin/onboarded-businesses/:businessSlug/evaluaitions/:testslug",
    component: BusinessTestDetailPage,
    isUserRoute: true,
    isAdminRoute: true,
    hideChatBot: true,
  },
];
export default adminRoutes;
